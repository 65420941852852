import { ChannelTypeString, SimpleChannel } from "../channel"
import { SlotContext, SlotDomainValueDto, SlotType } from "../slot"
import isEmpty from "lodash/isEmpty"
import { DisplayTypeValue } from "../survey"
import { MessageAttachment, Sticker } from "./message"

export interface DialogPerson {
    IdInChannel: string
    OmniUserId: string
    Firstname: string
    Middlename?: string
    Lastname: string
    Picture?: string
    Id?: string //@TODO Temporarly because backend tell
}

export interface OperatorFullName {
    Firstname: string
    Middlename?: string
    Lastname: string
}

/**
 * A client who have a dialog with an operator
 */
export interface DialogClient {
    /**
     * Client's ID in the system across all channels
     **/
    OmniUserId: string
    /** Client's ID within a channel **/
    Firstname: string
    Lastname: string
    Middlename?: string
    IdInChannel?: string
    Picture?: string
}

export interface DialogProject {
    Id: string
    Title?: string
    Picture?: string
}

export interface DialogQueue {
    Id: string
    Title?: string
}

export interface IGetOperatorClientBadgesResponse {
    OmniUserId: string
    Firstname?: string
    Lastname?: string
    Middlename?: string
    Picture?: string
    ProjectId: string
    IdInChannel?: string
}

export interface ISearchClientsByCriterionResponse {
    OmniUserId: string
    Firstname?: string
    Lastname?: string
    Middlename?: string
    Picture?: string
    ProjectId: string
}

export interface ISearchOperatorsByCriterionResponse {
    OperatorId: string
    Firstname: string
    Lastname: string
    Middlename: string
    Picture?: string
    Login: string
    RoleId: string
    Status: string
}

export interface ISearchOperatorsByCriterionRequest {
    query: string
    roles: string[]
    statuses: string[]
    queues: string[]
    projectId?: string
    includeCurrentUser: boolean
}

export interface IGetOperatorsListResponse {
    OmniUserId: string
    Firstname?: string
    Lastname?: string
    Middlename?: string
    Picture?: string
    IdInChannel?: string
}

export interface IGetOperatorsListRequest {
    projectId: string
    operators: string[]
}

export interface DialogTopic {
    Id: string
    Title: string
    Nodes: DialogTopic[]
}

export interface IGetDialogTopicsRequest {
    ProjectId: string
}

export type IGetDialogTopicsResponse = DialogTopic

export interface IGetOperatorClientResponse {
    Id: string
    Firstname: string
    Lastname: string
    Middlename: string
    Picture?: string
    Project: DialogProject
    ActiveDialogId?: string
    SlotContext: SlotContext
}

export interface IGetOperatorClientRequest {
    omniUserId: string
    projectId: string
}

export interface IGetOperatorChannelsRequest {
    omniUserId: string
    projectId: string
}

export interface DialogBadge {
    Id: string
    Client: DialogPerson
    Channel?: SimpleChannel
    Project: DialogProject
    Queue?: DialogQueue
    Stat: DialogBadgeStat
    Preview: string
    UnreadMessages: number
    IsReplied?: boolean
    IsHoldEnabled: boolean
}

export interface DialogBadgeStat {
    Routed: number
    LastOutgoingMessageSent: number
    LastIncomingMessageSent: number
}

export interface DialogsSurveySlot {
    Id: string
    Slot: {
        SlotId: string
        Title: string
        Type: SlotType
        Domain: SlotDomainValueDto[]
        IncreasedSize: boolean
        DisplayType: DisplayTypeValue
        ReadOnly: boolean
        IsCopyRestricted: boolean
        IsRequiredToFill: boolean
    }
}

type DialogSurveysEmptyResponse = {}

export type DialogsSurveysResponse = {
    DialogSurvey: {
        Id: string
        Title: string
        Fields: DialogsSurveySlot[]
    }
    ClientSurvey: {
        Id: string
        Title: string
        Fields: DialogsSurveySlot[]
    }
}

export enum DialogSendMessageActionsIds {
    SendMessageAndHold = "send-message-and-hold"
}

export interface DialogSendMessageAction {
    Id: DialogSendMessageActionsIds
    Title: string
}

export type DialogsSurveys = DialogsSurveysResponse | DialogSurveysEmptyResponse

export type DialogsSurveysRequest = {
    projectId: string
    queueId?: string
}

export const isDialogsSurveysResponse = (data: DialogsSurveys): data is DialogsSurveysResponse => {
    return !isEmpty(data)
}

export interface DialogAgent {
    // agent_id
    // For instance aiassist2, routingagent, initialmsgagent etc
    Id: string
    // cust_agent_id
    // For instance aiassist2_my_cool_agent, initialmsgagent1, initialmsgagent2
    Name: string
}

export interface DialogBadgeUpdate {
    Id: string
    Preview?: string
    UnreadMessages?: number
}

export interface ApiDialogStatistic {
    Dispatched: number
}

export type DialogTopicArray = { Id: string; Title: string }[]

export interface IUpdateDialogTopicsRequest {
    DialogId: string
    Topics: DialogTopicArray[]
}

export interface Dialog {
    Id: string
    Client: DialogClient
    Operator: DialogPerson
    Channel: SimpleChannel
    Project: DialogProject
    Queue?: DialogQueue
    Agent: DialogAgent
    Stat: ApiDialogStatistic
    SlotContext: SlotContext
    Topics?: DialogTopicArray[]
    RoutingMessage?: string
}

export interface InactiveDialog {
    Client: DialogClient
    Channel: {
        Id: string
        Type: ChannelTypeString
    }
    Project?: {
        Id: string
    }
    Queue?: {
        Id: string
    }
    Id?: string
}

export const isActiveDialogTG = (dialog: Dialog | InactiveDialog): dialog is Dialog => {
    return (
        dialog &&
        "Id" in dialog &&
        "Operator" in dialog &&
        "Stat" in dialog &&
        "Agent" in dialog &&
        "SlotContext" in dialog
    )
}

export interface IOperatorDialogTransferToQueueRequest {
    DialogId: string
    QueueId: string
    Message: string
}

export interface IOperatorDialogTransferToOperatorRequest {
    DialogId: string
    ByLink?: boolean
    OperatorId: string
    QueueId: string
    Message: string
}

export interface IGetDialogTransferAvailableQueues {
    Categories: Array<{
        Id: string
        Name: string
        Queues: Array<{
            Id: string
            Name: string
            IsDefault: boolean
        }>
    }>
}

export interface IGetDialogTransferAvailableQueuesByOperatorRequest {
    projectId: string
    operatorId: string
    channelId?: string
}

export interface ISearchOperatorsByCriterionNativeResponse {
    login: string
    firstname: string
    lastname: string
    middlename: string
    picture: string
    MaxDialogs: number
    isDeleted: boolean
    position: {
        ID: string
        Title: string
        CustomerId: string
    }
}

export interface IGetUserChannelsRequest {
    projectId: string
    omniUserId: string
}

export interface IGetOperatorChannelsResponse {
    channel_id: string
    channel_title: string
    channel_type: ChannelTypeString
}

export interface IGetUserChannelsResponse {
    channel_id: string
    channel_user_id: string
    omni_user_id: string
    customer_id: string
    created_at: number
    ext_user_id?: string
}

export interface ISetDialogChannelRequest {
    dialogId: string
    channelId: string
    channelUserId: string
    channelType: ChannelTypeString
}

export const enum MessageActionsStatusResponse {
    Success,
    SentMessageNotFoundInActiveDialog,
    ThisDialogIsNotActive
}

export type DeleteMessageRequest = {
    projectId: string
    DeleteMessageBody: DeleteMessageBody
}

export type DeleteMessageBody = {
    Id: string
    DialogId: string
    OmniUserId: string
    ChannelId: string
    ChannelType: ChannelTypeString
}

export type EditMessageBody = {
    Id: string
    DialogId: string
    OmniUserId: string
    ChannelId: string
    ChannelType: ChannelTypeString
    Text: string
    Attachments?: MessageAttachment[]
    Sticker?: Sticker
}

export type EditMessageRequest = {
    projectId: string
    EditMessageBody: EditMessageBody
}

export interface SearchUserRequest {
    projectId: string
    channelId: string
    channelUserId: string
}

export type WithClient<T> = T & { client: IGetOperatorClientResponse }
export type WithDialog<T> = T & { dialog: Dialog }

export enum SurveyType {
    client = "client",
    dialog = "dialog"
}

export interface GetDialogTransferAvailableQueuesRequest {
    projectId: string
    channelId?: string
    queueId?: string
}

export enum ReadonlyChatReasons {
    statusInvalid = "statusInvalid",
    otherDialog = "otherDialog",
    disabled = "disabled"
}

export type ReadonlyChat = ReadonlyChatReasons | null

export interface GetDialogOldResponse {
    cur_session?: Record<string, unknown>
    context: [string, string][]
}

export interface GetDialogAttachment {
    Url: string
    Name: string
    Size: number
    UploadedAt: number
    IsOperator: boolean
    UploaderFirstName: string
    UploaderLastName: string
}
